import React from "react";
import { useAuthGoogleContext } from "context/AuthenticateGoogleContext";
import { BallTriangle } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";

const GOOGLE_CLIENT_AUTH_ID = process.env.REACT_APP_CLIENT_ID_AUTH || "189121918977-t32044hdb4nqidg9t0cmgu9l8s8cgf8h.apps.googleusercontent.com";

export default function LoginPage() {
  const [loginFailed, setLoginFailed] = React.useState<boolean>(false);
  const [textLoginFailed, setTextLoginFailed] = React.useState<string>("Login failed");
  const history = useHistory();
  const { login, setLogin, listDataAdminPartner, isLoading } = useAuthGoogleContext();
  React.useEffect(() => {
    if (login) {
      if (process.env.REACT_APP_INTERNAL_SITE === "true") history.push("/collections");
      else {
        history.push("/admin-tools/distribute-airdrop");
      }
    }
  }, [login, isLoading]);

  if (isLoading || login === undefined || login)
    return (
      <div className="w-full h-screen flex flex-col text-white text-xl md:text-3xl font-bold justify-center items-center">
        <BallTriangle height={100} width={100} radius={5} color="purple" ariaLabel="ball-triangle-loading" visible={true} />
      </div>
    );
  else
    return (
      <div className="pt-[64px] w-full h-full flex items-center justify-center">
        <div className="w-[300px] h-[300px] rounded-[10px] border border-gray-800 flex flex-col justify-around items-center shadow-2xl">
          <div className="flex flex-col items-center">
            <img src="assets/images/google.png" className="mb-3 w-11 h-11" alt="google-img" />
            Sign in with Google
          </div>
          <LoginSocialGoogle
            scope="email profile"
            client_id={GOOGLE_CLIENT_AUTH_ID}
            onReject={(err) => {
              setTextLoginFailed("Login Failed");
              console.log("Rejected: ", err);
            }}
            onResolve={async ({ provider, data }: any) => {
              await listDataAdminPartner.refetch();
              if (
                (data.hd === "myria.com" && process.env.REACT_APP_INTERNAL_SITE === "true") ||
                (listDataAdminPartner.data && listDataAdminPartner.data.includes(data.email) && process.env.REACT_APP_INTERNAL_SITE !== "true")
              ) {
                localStorage.setItem("accessToken", data.access_token);
                setLogin(true);
              } else {
                localStorage.removeItem("accessToken");
                setLogin(false);
                setLoginFailed(true);
                setTextLoginFailed("User do not have permission to access.");
              }
            }}
          >
            <div className="py-[10px] px-5 rounded-md border border-gray-700 flex items-center hover:cursor-pointer hover:bg-gray-400">
              <img src="assets/images/google.png" className="w-6 h-6 mr-3" />
              Google Login
            </div>
          </LoginSocialGoogle>
          {loginFailed ? <div className="text-red-600 justify-center flex-wrap">{textLoginFailed}</div> : ""}
        </div>
      </div>
    );
}
